import React from 'react'
import './base.css'

class Template extends React.Component {
  render() {
    const { children } = this.props

    return (
      <>
        {/* <Navigation /> */}
        {children}
      </>
    )
  }
}

export default Template
